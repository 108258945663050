   /*
    * Cart preview
    */

    $('.upiti-row .row').each(function(index, value) {
        const inputValueCart = $(this).closest('.cart-products-preview').find('.quantity-cart').text();
        const currentValue = $(this).closest('.cart-products-preview').find('.unesi-kolicinu').val();
        const iconArrowRight = $(this).closest('.cart-products-preview').find('.plus');
 
        // console.log(iconArrowRight);
        
        if(currentValue > parseInt(inputValueCart)) {
            iconArrowRight.addClass('disabled-quantity');

        }
      
    });

    $("select[name='cart_payment_type']").on('change', function(){
        updateCartPaymentType($(this).val());
    });

//    $('.cart-header').on('click', function (e) {
//        e.preventDefault();
//        $(this).toggleClass('active');
//        $('.floating-cart').toggleClass('opened');
//        $('.overlay-black').addClass('active');
//        $('.navigation-bottom.mobile').toggleClass('hide');
//    });

   //Remove opend class for cart
   $('.floating-cart-btn').on('click', function () {
       $(this).removeClass('opened');
       $('.floating-cart').removeClass('opened');
       $('.cart-header').removeClass('active');
       $('.overlay-black').removeClass('active');

       $('.navigation-bottom.mobile').toggleClass('hide');
   });

   $('.cart-header.active').on('click', function () {
       $(this).removeClass('active');
       $('.floating-cart.opened').removeClass('opened');
   });

   $(document).mouseup(function (e) {
       var floatingCart = $(".floating-cart.opened");

       // if the target of the click isn't the container nor a descendant of the container
       if (!floatingCart.is(e.target) && floatingCart.has(e.target).length === 0) {
           $('.floating-cart').removeClass('opened');
           $('.overlay-black').removeClass('active');
           $('.navigation-bottom.mobile').removeClass('hide');
       }

   });

   //Add to cart btn
   $('.popup-add-to-cart .close-btn span').on('click', function (e) {
       $('.popup-add-to-cart').removeClass('active');
   });


   /*
   *    Update price on cart page - product
   */
   function totalCartPriceCalculate() {
       //
       totalCartPrice = 0;
       const askForPriceText = null;
       $('.upiti-row .product-total-price').each(function (key, value) {
           if (value.textContent.includes(',')) {
               totalCartPrice += parseFloat(value.textContent.replace(/[.]/g, '').replace(/[,]/g, '.'));
           } else {
               totalCartPrice += parseFloat(value.textContent);
           }
       });
       
       if (totalCartPrice > 0) {
           $('#cart-total-price-mob').text(totalCartPrice.toFixed(2));
           $('#cart-total-price').text(totalCartPrice.toFixed(2));
       } else {
           $('#cart-total-price-mob').text(askForPriceText);
           $('#cart-total-price').text(askForPriceText);
       }
   }

   function updateCartQuantity(productId, quantity)
   {
        const urlDomain = window.location.origin;
        const token = $("input[name='_token']").val();

        $.ajax({
            url: urlDomain + "/cart/update-quantity",
            type: "PATCH",
            headers: {
                'X-CSRF-TOKEN': token
            },
            data: {
                product_id: productId,
                token,
                quantity: quantity,
            },
            success: function (res) {
                // setTimeout(function () {
                    // location.reload();
                // }, 2000);

            },
            error: function (err) {
                console.log(err);
            },
        });
   }

   function updateCartPaymentType(paymentType)
   {
        const urlDomain = window.location.origin;
        const token = $("input[name='_token']").val();
        
        $.ajax({
            url: urlDomain + "/cart/update-payment-type",
            type: "POST",
            headers: {
                'X-CSRF-TOKEN': token
            },
            data: {
                cart_payment_type: paymentType,
                token
            },
            success: function (res) {
                setTimeout(function () {
                    window.location.href = window.location;
                }, 500);

            },
            error: function (err) {
                console.log(err);
            },
        });
   }

   function updateTotalCartPriceInit() {
       let cartItems = $('.cart-products-preview');
       const askForPriceText = null;
       cartItems.each(function (index) {
        
           let input = $(this).find('input[name="items[' + index + '][quantity]"]');
           let productPrice = +$(this).find('.product-price').text();
           let totalProductPrice = $(this).find('.product-total-price');
           
           if (isNaN(productPrice)) {
               totalProductPrice.text(askForPriceText);
           } else {
               totalProductPrice.text(new Intl.NumberFormat('de-DE').format((parseFloat(input.val()) * productPrice).toFixed(2)));
           }
       });

       totalCartPriceCalculate();
   }

   updateTotalCartPriceInit();


   $('.minus').click(function () {

        const quantity = $(this).closest('.cart-products-preview').find('.quantity-cart').text(); 
        const productId = $(this).closest('.cart-products-preview').data('product-real-id');
        var input = $(this).parent().find('input');
        let packageMin = +$(this).closest('.cart-products-preview').find('.commercial-package').val();
        let productPrice = +$(this).closest('.cart-products-preview').find('.product-price').text();
        let totalProductPrice = $(this).closest('.cart-products-preview').find('.product-total-price');
        var count = parseFloat(input.val()) - packageMin;

        count = count < 0 ? 0 : count;
        input.val(count);
        input.change();
        
        $(this).parent().find('.plus').removeClass('disabled-quantity');

        totalProductPrice.text(new Intl.NumberFormat('de-DE').format((parseFloat(input.val()) * productPrice).toFixed(2)));
        totalCartPriceCalculate();
        updateCartQuantity(productId, input.val());
        return false;

   });

   $('.plus').click(function () {

        const quantity = $(this).closest('.cart-products-preview').find('.quantity-cart').text();
        const productId = $(this).closest('.cart-products-preview').data('product-real-id');
        var input = $(this).parent().find('input');
        let packageMin = +$(this).closest('.cart-products-preview').find('.commercial-package').val();
        let productPrice = +$(this).closest('.cart-products-preview').find('.product-price').text();
        let totalProductPrice = $(this).closest('.cart-products-preview').find('.product-total-price'); 

        input.val(parseFloat(input.val()) + packageMin);
        input.change(); 

        if(input.val() > parseInt(quantity)) { 
            $(this).addClass('disabled-quantity');
            input.val(input.val() - packageMin);

        } else { 
            $(this).removeClass('disabled-quantity');
        }
        
        totalProductPrice.text(new Intl.NumberFormat('de-DE').format((parseFloat(input.val()) * productPrice).toFixed(2)));
        totalCartPriceCalculate();
        updateCartQuantity(productId, input.val());
        return false;
   });

   $(".unesi-kolicinu").on("keydown", function () {

        let timeoutId = 0;
        clearTimeout(timeoutId); // doesnt matter if its 
       
        timeoutId = setTimeout(() => {

           // TOTAL PACK
           let packageMin = +$(this).closest('.cart-products-preview').find('.commercial-package').val();
           let productPrice = +$(this).closest('.cart-products-preview').find('.product-price').text();
           let totalProductPrice = $(this).closest('.cart-products-preview').find('.product-total-price');
           const productId = $(this).closest('.cart-products-preview').data('product-real-id');
           let reqQuanty = +$(this).val();
           let totalPackageFiled = $(this);
           let totalPackageSum = 0;

           if (reqQuanty % packageMin !== 0) {
               totalPackageSum = reqQuanty + (packageMin - (reqQuanty % packageMin));
           } else {
               totalPackageSum = reqQuanty;
           }
           totalPackageFiled.val(totalPackageSum);
           totalProductPrice.text(new Intl.NumberFormat('de-DE').format((parseFloat(totalPackageFiled.val()) * productPrice).toFixed(2)));
           totalCartPriceCalculate();
           updateCartQuantity(productId, reqQuanty);
       }, 500);
       // return false;
   });


   //Show success message
   setTimeout(function () {
       $('.popup-add-to-cart').addClass('active');
   }, 1000);

   setTimeout(function () {
       $('.popup-add-to-cart').removeClass('active');
   }, 3500)
