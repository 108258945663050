/*
 *   Append explore filter to mobile
 */
function appendFilters() {
  if ($(window).width() < 1025) {
    $("#filter-accordion").appendTo(".filter-form-mobile");
  }
}
appendFilters();
$(window).resize(appendFilters);

/*
 * Show Filter mobile
 */
$(".filter-button").on("click", function () {
  $(".filters.mobile").addClass("filter-up");
  $(".navigation-bottom.mobile").toggleClass("hide");
});

$(".filter-button-back").on("click", function () {
  $(".filters.mobile").removeClass("filter-up");
  $(".floating-cart.opened").removeClass("opened");
});

/*
 *   Show toast message on click label
 */
$("body").on("click", ".custom-control-label", function () {
  $(".toast-message").fadeIn(400).delay(2000).fadeOut(400);
});

$("body").on("change", ".range-input input", function () {
  $(".toast-message").fadeIn(400).delay(2000).fadeOut(400);
});

/*
 *   Toggle icon on filters
 */
// $("#filter-accordion").on("click", ".card-header", function () {
//     // $(".up-down-arrow").toggleClass("down-arrow");
//     $(".p-chevron-down", this).toggleClass("p-chevron-up");

// });

/*
 *   Filter desktop
 */

$(document).ready(function () {
  window.addEventListener("popstate", (event) => {
    window.location.replace(document.location);
  });

  $(document.body).on(
    "change",
    "input[type='radio'][name='subcategory'], input[type='number'][name='min_price'], input[type='number'][name='max_price'], input[type='radio'][name='category']",
    function (e) {
      var params =
        "?" +
        $("#filter :input[value!='']")
          .filter(function (index, element) {
            return $(element).val() != "";
          })
          .serialize();
      // console.log(params);
      $.get("getfilters?" + params, function (data) {
        $(".filter-box").html(data);
      });
    }
  );
  $(document.body).on("change", "#filter", function (e) {
    setAdditionalFilterName(e.target);

    var search = new window.URLSearchParams(window.location.search);
    search = search.get("search") ? search.get("search") : "";
    var params =
      "?" +
      "search=" +
      search +
      "&" +
      $("#filter :input[value!='']")
        .filter(function (index, element) {
          return $(element).val() != "";
        })
        .serialize();

    $.get(params + "&ajax=1", function (data) {
      $(".products").html(data);
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
      window.history.pushState("", "", params);

      $('[data-toggle="tooltip"]').tooltip();

      $(".color-list-item").on("click", function (e) {
        var urlDomain = window.location.origin;
        let Id = $(this).data("product-id");
        let colorId = $(this).data("color-id");
        let element = $(this).closest(".product-content").find(".product-list-image img");
        let link = $(this).closest(".product-content").find("a");

        $(".product-content").find(".active").removeClass("active");
        $(this).toggleClass("active").next();

        $.ajax({
          url: urlDomain + "/api/products/getColorImage?id=" + Id,
          type: "GET",
          success: function (res) {
            element.attr("src", res);
            link.attr("href", function (i, href) {
              return link.data("url") + "?color=" + colorId;
            });
          },
          error: function (err) {},
        });
      });
    });
    //this.submit();
  });

  function setAdditionalFilterName(el) {
    const name = $(el).attr("name");
    const target = $(el)
      .closest(".collapse")
      .find("input[name='tn-" + name + "']");

    target.val($(el).data("name"));
  }
});

/*
 *   Price range
 */

const rangeInput = document.querySelectorAll(".range-input input");
const priceInput = document.querySelectorAll(".price-input input");
const progress = document.querySelector(".slider .progress-slider");
let priceGap = 1;

priceInput.forEach((input) => {
  input.addEventListener("input", (e) => {
    let minVal = parseFloat(priceInput[0].value).toFixed(2);
    let maxVal = parseFloat(priceInput[1].value).toFixed(2);

    if (maxVal - minVal >= priceGap && maxVal <= 1000) {
      if (e.target.className === "input-min") {
        rangeInput[0].value = minVal;
        progress.style.left = (minVal / rangeInput[0].max) * 100 + "%";
      } else {
        rangeInput[1].value = maxVal;
        progress.style.right = 100 - (maxVal / rangeInput[1].max) * 100 + "%";
      }
    }
  });
});

rangeInput.forEach((input) => {
  input.addEventListener("input", (e) => {
    let minVal = parseFloat(rangeInput[0].value).toFixed(2),
      maxVal = parseFloat(rangeInput[1].value).toFixed(2);

    if (maxVal - minVal < priceGap) {
      if (e.target.className === "min_price") {
        rangeInput[0].value = maxVal - priceGap;
      } else {
        rangeInput[1].value = minVal + priceGap;
      }
    } else {
      priceInput[0].value = minVal;
      priceInput[1].value = maxVal;
      progress.style.left = (minVal / rangeInput[0].max) * 100 + "%";
      progress.style.right = 100 - (maxVal / rangeInput[1].max) * 100 + "%";
    }
  });
});

// Prevent refresh the form
$("#filter .filter-box .card-header button").on("click", function (e) {
  e.preventDefault();
});

/*
 *   Search autocomplete
 */

$("#autocomplete-search, #autocomplete-search-mobile").on("keyup", function () {
  var search = $(this).val();

  var urlPrefix = $("#search-product, #search-product-mobile").data("url");
  var urlDomain = window.location.origin;

  if (search != "") {
    $(".autocomplete-list").css("height", "250px");

    $.ajax({
      url: urlDomain + "/" + urlPrefix + "/ajax/searchbar?search=" + search,
      type: "GET",
      dataType: "JSON",
      success: function (res) {
        // console.log(res);
        var len = res.length;
        $(".autocomplete-list").html("");

        for (var i = 0; i < len; i++) {
          console.log(res[i]);
          var name = res[i]["name"];
          var description = res[i]["description"];
          var image = res[i]["image"];
          var link = res[i]["url"];
          $(".autocomplete-list").append(`
                        <a href="${link}" class="item">
                            <div class="image">
                                <img width="90" height="90" src="${image}" alt="${name}" loading="lazy" class="img-responsive">
                            </div>
                            <div class="desc">
                                <h4>${name}, ${description}</h4>
                            </div>
                        </a>
                    `);
        }

        if (len === 0) {
          $(".autocomplete-list").append(
            '<div class="empty-search-res">Ne postoji proizvod za zadati kriterijum pretrage.</div>'
          );
        }
      },
      error: function (err) {
        // console.log(err);
      },
    });
  } else {
    $(".autocomplete-list").css("height", "0");
  }

  $("body").on("click", function () {
    $(".autocomplete-list").css("height", "0");
  });
});
